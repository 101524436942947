/*
    KEYFRAMES
    Les keyframes se renseignent ici
*/
$briks-keyframes: (
    banner: (
        from: (
            background-position-x: 0%
        ),
        to: (
            background-position-x: -1622px
        )
    )
);