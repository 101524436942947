/*
    POINT D'ENTRÉE EXTENSION
    Placer le chemin du fichier dans le fichier _config.yml de LibDoc
*/

/*
    CORE
    Fonctionnalités principales 
    Inclure briks/_core.scss pour obtenir les fonctions helpers et les mixins.
    Aucune déclaration CSS n'est générée à partir de ce fichier.
*/
@import 'briks/core';
/*
    DESIGN TOKENS
    Fichier SASS contenant les design tokens du projet
*/
@import 'briks/settings/tokens/ucar';
/*
    CONFIGURATION KEYFRAMES
    Requiert briks/_core.scss
    Imports du fichier de configuration des keygrames
*/
@import 'keyframes';
/*
    GÉNÉRATEUR DE KEYFRAMES
    Requiert  briks/_core.scss
    Génère toutes les déclarations CSS des keyframes
*/
@import 'briks/keyframes';
